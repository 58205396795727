<template>
  <div class="question_logo">
    <img alt="CPH Digital Tool" src="/gfx/cphd_tool_logo_red.png" />
  </div>
</template>

<script>
export default {
  name: "QuestionHeader"
}
</script>

<style scoped>
  .question_logo{
    max-width: 72px;
    margin-top: 42px;
    margin-bottom: 46px;
  }

  .question_logo img{
    max-width: 100%;
  }
</style>