<template>
  <div class="info_steps">
    <div class="info_step_count">
      <router-link v-for="route of stepRoutes" :key="route" :to="route.path" class="step"  :class="[currentStep == route.meta.step ? 'active' : '']"></router-link>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import {useRoute, useRouter} from "vue-router";

export default {
  name: "QuestionSteps",
  setup(){

    const route = useRoute();
    const router = useRouter();
    const appRoutes = router.getRoutes();

    const stepRoutes = computed(() => {
      const stepRoutes = appRoutes.filter( route => route.meta.step )
      return stepRoutes
    })

    const currentStep = computed( () => {
      return route.meta.step
    })

    return {
      stepRoutes,
      currentStep
    }
  }
}
</script>

<style scoped>
  .info_step_count{
    bottom: 0px;
    width: 100%;
    max-width: 206px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: justify;
    justify-content: space-between;
    position: relative;
    padding-bottom: 3rem;
  }

  .info_step_count .step{
    height: 1rem;
    width: 1rem;
    border: 1px solid #FF2A55;
    border-radius: 50%;
    transition: background-color 0.3s linear 0s;
    background-color: transparent;
    cursor: pointer;
  }

  .info_step_count .step.active{
    background-color: #FF2A55;
  }
</style>