<template>
  <div class="question_info_container">
    <div>
      <img class="info_avatar" alt="avatar animation" :src="`/gfx/avatars/${avatarImg}.svg`" >
    </div>
    <div>
      <h3>Hvorfor er dette trin vigtigt?</h3>
      <p class="question_info_description">{{ description }}</p>
      <div>
        <Button @click="goToOverview($event)" label="Gå direkte til oversigt" class="p-button-outlined" />
      </div>
    </div>
    <div class="info_steps">
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import {useRouter} from "vue-router";
import store from "@/store/store";

export default {
  name: "QuestionInfo",
  props: {
    description: String,
  },
  setup() {

    const router = useRouter();
    const avatarImg = computed( () => store.state.avatar )

    function goToOverview (){
      router.push('/persona-overview')
    }

    return{
      avatarImg,
      goToOverview
    }
  }
}
</script>

<style scoped>
h3{
  font-weight: 600;
}

.question_info_container{
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: transparent;
  padding: 20px;
  padding-top: 130px;
}

.question_info_container .info_steps{
  margin-top: auto;
}

.question_info_container .question_info_description{
  margin-bottom: 50px;
}

.info_avatar{
  display: block;
  max-width: 200px;
  max-height: 200px;
  margin: 0px auto;
}

</style>