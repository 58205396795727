<template>
  <div>
    <h1>Opret din avatar</h1>
    <h2>Trin 1/7</h2>
  </div>
  <div class="answer_field">
    <h3>Navngiv din Persona</h3>
    <span class="p-float-label">
        <InputText id="personaName" v-model="personaName" type="text"/>
        <label for="personaName">Indtast navn</label>
    </span>
  </div>
  <div class="answer_field">
    <div>
      <h3>Vælg din avatar</h3>
    </div>
    <div class="avatars">
      <div v-for="avatar of avatars" :key="avatars.indexOf(avatar)" @click="setAvatar(avatar)" class="avatar" :class="[chosenAvatar == avatar ? 'chosen' : '']">
        <img :src="`/gfx/avatars/${ avatar }.svg`" alt="avatar" />
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import store from '@/store/store';

export default {
  name: "CreateAvatarQuestions",
  components: {},
  setup(){
    // const avatars = [
    //   'avatar-0',
    //   'avatar-1',
    //   'avatar-2',
    //   'avatar-3',
    //   'avatar-4'
    // ];

    const avatars = [
      'avatar_blond_guy',
      'avatar_bussiness_man',
      'avatar_ginger_guy',
      'avatar_hiphop_guy',
      'avatar_hipster_guy',
      'avatar_mexican_guy',
      'avatar_nerd_guy',
      'avatar_nice_guy',
      'avatar_old_wise_man',
      'avatar_smart_guy',
      'avatar_beanie_girl',
      'avatar_shorthair_girl',
      'avatar_ginger_girl',
      'avatar_smart_girl',
      'avatar_longhair_girl',
      'avatar_fashion_girl',
      'avatar_working_girl',
      'avatar_pixie_girl'
    ];

    let personaName = computed({
      get: () => store.state.personaName,
      set: val => {
        store.dispatch(('setPersonaName'), {name: val})
      }
    });

    const chosenAvatar = computed( () => {
      if(store.state.avatar.length > 1){
        return store.state.avatar;
      } else {
        return null;
      }
    });

    function setAvatar(avatarId) {
      store.dispatch('setAvatar', {avatar: avatarId})
    }

    return{
      personaName,
      avatars,
      chosenAvatar,
      setAvatar,
    }
  }
}
</script>
<style scoped>

#personaName{
  max-width: 396px;
}

.avatars{
  display: flex;
  flex-wrap: wrap;
  background: transparent;
}

.avatars .avatar{
  max-width: 16.66%;
  flex: 0 0 16.66%;
  border-radius: 50%;
}

.avatar img{
  max-width: 100%;
}
.avatar.chosen{
  background: var(--primary-color);
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px;
}

</style>